export function currencyFormat(value, currency, decimals) {    
    return (
        new Intl.NumberFormat('en', {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'narrowSymbol',
            currencySign: 'accounting',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        }).format(value)
    );
}