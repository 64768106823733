import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home, Projects, MP, GC, Whoops404 } from './pages';

function App() {
  
  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/mealPlanner" element={<MP />} />       
        <Route path="/projects/gainCalculator" element={<GC />} />
        <Route path="*" element={<Whoops404 />} />
      </Routes>
    </div>
  );
}

export default App;
