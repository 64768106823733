import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { currencyFormat } from './Tools';

export function GainCalculator() {

    const currency = 'CAD';
    const decimals = 0;

    var [initialCapital, setInitialCapital] = useState(0);
    var [yearsOfGrowth, setYearsOfGrowth] = useState(0);
    var [growthRate, setGrowthRate] = useState(0);
    var [currentInclusionRate, setCurrentInclusionRate] = useState(0);
    var [newInclusionRate, setNewInclusionRate] = useState(0);
    var [marginalTaxRate, setMarginalTaxRate] = useState(0);
    var [currentValue, setCurrentValue] = useState(0);
    var [currentCapitalGainTax, setCurrentCapitalGainTax] = useState(0);
    var [ htmlTable1, setHtmlTable1 ] = useState([]);
    var [ htmlTable2, setHtmlTable2 ] = useState([]);
    var [ data, setData ] = useState([]);

    const TableFormatter = (value) => {
        if(value >= 1000000000){
            return (currencyFormat(value/1000000000, currency, decimals)) + 'B';
          }else if(value >= 1000000){
            return (currencyFormat(value/1000000, currency, decimals)) + 'M';
          }else if(value >= 1000){
            return (currencyFormat(value/1000, currency, decimals)) + 'K';
          }else{
            return value.toString();
          }
    }

    useEffect(() => {
        setCurrentValue(initialCapital*(1+(growthRate)/100)**yearsOfGrowth); 
        setCurrentCapitalGainTax((currentValue - initialCapital) * 
        currentInclusionRate/100 * marginalTaxRate/100);
        setHtmlTable1(getCase1(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, 
            marginalTaxRate));
        setHtmlTable2(getCase2(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, 
            marginalTaxRate));
        setData(setDataTable(getCase1(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, 
            marginalTaxRate), getCase2(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, 
                marginalTaxRate)));
    }, [currentValue, initialCapital, yearsOfGrowth, currentCapitalGainTax, growthRate, currentInclusionRate, newInclusionRate, marginalTaxRate]);

    return (
        <>
            <h1> Capital Gain Calculator </h1>
            <div className="gc-container" id="gc-layout">
                <div id="gc-input">
                    <div id="gc-instructions">
                        <br/>
                        <h2>Instructions:</h2>
                        1. Initial capital amount is the amount of capital in the past. 
                        <br/>
                        2. Enter years of growth since initial capital.
                        <br/>
                        3. Enter the assumed average growth rate. 
                        <br/>
                        4. Enter the current and future inclusion rates.
                        <br/>
                        5. Enter the marginal tax rate.
                        <br/>
                        6. The Net Value shown is the value after taxes are paid in that year.
                        <br/>
                        <br/>
                    </div> {/*End of id:gc-instructions*/}
                    <div id="gc-input-area">
                        <form>
                            <label>Enter initial capital amount ({currency}$) : 
                                <input 
                                type="text" 
                                value={initialCapital}
                                onChange={(e) => setInitialCapital(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label>Enter years of growth of initial capital (years) :
                                <input
                                type="text"
                                value={yearsOfGrowth}
                                onChange={(e) => setYearsOfGrowth(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label>Enter assumed growth rate (%) : 
                                <input 
                                type="text" 
                                value={growthRate}
                                onChange={(e) => setGrowthRate(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label>Enter current inclusion rate (%) : 
                                <input 
                                type="text" 
                                value={currentInclusionRate}
                                onChange={(e) => setCurrentInclusionRate(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label>Enter future inclusion rate (%) :
                                <input
                                type="text"
                                value={newInclusionRate}
                                onChange={(e) => setNewInclusionRate(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label>Enter marginal tax rate (%) :
                                <input
                                type="text"
                                value={marginalTaxRate}
                                onChange={(e) => setMarginalTaxRate(e.target.value)}
                                />
                            </label>
                            <br/>
                            <label> Current value of account is : {currencyFormat(currentValue, currency, decimals)}
                            </label>
                            <br/>
                            <label> Tax on gain to be paid today : {currencyFormat(currentCapitalGainTax, currency, decimals)}
                            </label>
                        </form>
                    </div> {/*End of id:gc-input-area*/}
                </div> {/*End of id:gc-instructions*/}
                <div id="gc-chart">
                    <h2 style={{paddingBottom: 15}}>Case 1 versus Case 2</h2>
                    <ResponsiveContainer width="100%" aspect="3">
                        <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={TableFormatter}/>
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="case1" stroke="#828489" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="case2" stroke="#C8AD55" />
                        </LineChart>
                    </ResponsiveContainer>
                </div> {/*End id-gc-chart*/}
                <div  id="gc-table">
                    <h2>Case 1 - Paying the Gain Tax at year 0</h2>
                    <h2>Case 2 - Carrying the gain forward</h2>
                    <div id="gc-case1-table">
                    <RenderTable
                    label="Case 1"
                    inputArray={htmlTable1} 
                    currency={currency}
                    decimals={decimals} 
                    />
                    </div>
                    <div id="gc-case2-table">
                    <RenderTable 
                    inputArray={htmlTable2} 
                    currency={currency}
                    decimals={decimals} 
                    />    
                    </div>
                </div>
            </div>
        </>
    );
}

// Helper Functions
// ----------------------------------------------------------------------------- //

function getCase1(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, marginalTaxRate) {
    
    let case1 = [];
    let currentCapital;
    let pastCapital;
    let capitalGain;
    let capitalGainTax;

    currentCapital = currentValue - currentCapitalGainTax;

    case1[0] = [currentValue, currentValue - initialCapital, currentCapitalGainTax, currentCapital];
    for(let i=1; i<=25; i++) {
        pastCapital = currentCapital;
        currentCapital = pastCapital * (1 + growthRate/100);
        capitalGain = currentCapital - case1[0][3];
        capitalGainTax = capitalGain * newInclusionRate/100 * marginalTaxRate/100;
        case1[i] = [currentCapital, capitalGain, capitalGainTax, currentCapital - capitalGainTax];
    }
    return case1;
}

function getCase2(currentValue, initialCapital, currentCapitalGainTax, growthRate, newInclusionRate, marginalTaxRate) {
    
    let case2 = [];
    let currentCapital;
    let pastCapital;
    let capitalGain;
    let capitalGainTax;

    currentCapital = currentValue - currentCapitalGainTax;

    case2[0] = [currentValue, currentValue - initialCapital, currentCapitalGainTax, currentCapital];
    for(let i=1; i<=25; i++) {
        pastCapital = case2[i-1][0];
        currentCapital = pastCapital * (1 + growthRate/100);
        capitalGain = currentCapital - initialCapital;
        capitalGainTax = capitalGain*newInclusionRate/100*marginalTaxRate/100;
        case2[i] = [currentCapital, capitalGain, capitalGainTax, currentCapital - capitalGainTax];
    }
    return case2;
}

function RenderTable(props) {

    const currency = props.currency;
    const decimals = props.decimals;

    let htmlTable = props.inputArray;
    let rows = htmlTable.map((number, i) => {
        let entry = number.map((element, j) => {
                return (
                    <td key={j}>{ currencyFormat(element, currency, decimals) }</td>
                );                 
        });
        return (
            <tr key={i}>
                <td>{i}</td>
                {entry}
            </tr>
        );  
    }); 
    return (
        <table>
            <tbody>
                <tr>
                    <td>Year</td>
                    <td>Account</td>
                    <td>Total Gain</td>
                    <td>Gain Tax</td>
                    <td>Net Value</td>
                </tr>
                {rows}    
            </tbody> 
        </table>
    );
}

function setDataTable(case1, case2) {

    var obj = {
        table:[]
    };

    for(let i = 0; i < case1.length; i++) {

        obj.table.push({name: i.toString(), case1: case1[i][3], case2: case2[i][3]});
        
    }
    console.log(obj.table);
    return obj.table;
}

