import React from 'react';
import { useLocation } from 'react-router-dom';
import { HomePage } from './javascript/HomePage';
import { ProjectsPage } from './javascript/ProjectsPage';
import { MealPlanner } from './javascript/MealPlanner';
import { GainCalculator } from './javascript/GainCalculator';

export function Home() {
    return (
        < HomePage />
    );
}

export function Projects() {
    return (
        < ProjectsPage />
    )
}

export function MP() {
    return (
        <MealPlanner />
    )
}

export function GC() {
    return (
        <GainCalculator />
    )
}

export function Whoops404() {

    let location = useLocation();
    console.log(location);

    return (
        <div>
            <h1>Resource not found at { location.pathname }!</h1>
        </div>
    );
}