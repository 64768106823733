import { Link } from 'react-router-dom';

export function ProjectsPage() {
    return(
        <>
            <h1>List Of Current Projects:</h1>
            <nav className="projects-nav" id="projects-nav">
                <Link to="mealPlanner">Meal Planner</Link>
                <Link to="gainCalculator">Capital Gain Calculator</Link>
            </nav>
        </>
    );
}